import { v4 as uuid } from "uuid";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import { fields } from "./fields";

export const modalElements = [
  {
    id: uuid(),
    isEdited: false,
    title: "Заголовок",
    tooltip: "Заголовок описи дел",
    className: "info-modal__element_title",
    value: fields.HEADER,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "№ описи дел",
    tooltip: "Номер описи дел в ОИК",
    className: "info-modal__element",
    value: fields.NUMBER,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Год",
    tooltip: "Год за который сформирована опись дел",
    className: "info-modal__element",
    value: fields.YEAR,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "ОИК",
    tooltip: "Краткое наименование ОИК",
    isLink: true,
    linkRights: [authorities.OIK_LIST],
    className: "info-modal__element_title",
    value: fields.OIK_SHORT_NAME,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Фонд",
    tooltip: "Номер фонда",
    isLink: true,
    linkRights: [authorities.FUND_LIST],
    className: "info-modal__element",
    value: fields.FUND_NUMBER,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Вид",
    tooltip: "Вид описи дел может принимать значения Больше или равно 10 лет, Постоянно, По личному составу",
    className: "info-modal__element",
    value: fields.CATEGORY,
    isShowContentTooltip: true,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Тип",
    tooltip: "Тип описи дел может принимать значения Передача документов, Изменение сроков хранения документов",
    className: "info-modal__element",
    value: fields.KIND,
    isShowContentTooltip: true,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Дата утверждения",
    tooltip: "Дата утверждения описи дел",
    className: "info-modal__element",
    value: fields.APPROVE_DATE,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Дата поступления",
    tooltip: "Дата поступления описи дел в ЦХЭД",
    className: "info-modal__element",
    value: fields.CREATE_DATE_TIME,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Статус",
    tooltip: "Статус описи дел может принимать значения: Утверждена, Удалена",
    className: "info-modal__element",
    value: fields.STATUS,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Тип проекта описи",
    tooltip: "Тип проекта описи может принимать значения: Опись бумажных дел, Опись ЭАД",
    className: "info-modal__element_title",
    value: fields.TYPE,
  },
];

export const historicalNoteElements = [
  {
    id: uuid(),
    isEdited: false,
    title: "Идентификатор",
    tooltip: "Идентификатор",
    className: "info-modal__element",
    value: fields.NOTE_GUID,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Код фонда",
    tooltip: "Код фонда",
    className: "info-modal__element",
    value: fields.NOTE_FUND_CODE,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Дата создания",
    tooltip: "Дата создания",
    className: "info-modal__element",
    value: fields.NOTE_CREATE_DATE,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Наименование",
    tooltip: "Наименование",
    className: "fund-info-modal__historical-reference info-modal__element_full-size info-modal__element_textarea",
    value: fields.NOTE_TITLE,
    type: "textarea",
  },
];

export const techElements = [];

export const fullModalElements = [...modalElements, ...techElements];
